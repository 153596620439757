import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import dayjs from "dayjs";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { IconAngleDownBig, IconClock } from "../utils/Icons";

const Orders = () => {
  const { t } = useTranslation();
  const basePath = "orders.";

  const [userData, setUserData] = useState(null);

  const getUserDataFromLocalStorage = () => {
    const storedUserData = localStorage.getItem("exchangeFormData");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
    }
  };

  useEffect(() => {
    getUserDataFromLocalStorage();
  }, []);

  return (
    <main>
      <NavBar className="bg-white" userData={userData} />
      <div className="order">
        <div className="container p-col-6">
          <div className="box">
            <div className="side">
              <div className="list">
                <a href="/personal/orders/" className="item active">
                  <img
                    src={require("../assets/img/icon/calendar.png")}
                    alt=""
                  />
                  <span>{t(`${basePath}side.orders`)}</span>
                </a>
                <a href="/" className="item">
                  <img
                    src={require("../assets/img/icon/log-out.png")}
                    alt=""
                    style={{ maxWidth: "18px", maxHeight: "18px" }}
                  />
                  <span>{t(`${basePath}side.logout`)}</span>
                </a>
              </div>
            </div>
            <div className="main">
              <div className="title">
                <h1>{t(`${basePath}title`)}</h1>
              </div>

              <table className="table-order">
                <tbody>
                  <tr>
                    <td>
                      <b>E526109</b>
                    </td>
                    <td className="date">
                      <span>
                        {dayjs(userData?.submitTime).format("DD.MM.YYYY")}
                      </span>
                      <span>{dayjs(userData?.submitTime).format("HH:ss")}</span>
                    </td>
                    <td>
                      <div className="currency">
                        <div className="item">
                          <span>{userData?.infoCoinFrom.name}</span>
                          <span>
                            {userData?.amount} {userData?.infoCoinFrom.symbol}
                          </span>
                        </div>
                        <div className="arrow">
                          <IconAngleDownBig
                            width="22"
                            height="30"
                            fill="#9EA6AD"
                          />
                        </div>
                        <div className="item">
                          <span>{userData?.infoCoinTo.name}</span>
                          <span>
                            {userData?.result}{" "}
                            {userData?.infoCoinTo.labelWallet === "Wallet"
                              ? userData?.infoCoinTo.symbol
                                  .substring(0, 3)
                                  .toUpperCase()
                              : userData?.infoCoinTo.symbol}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="progress">
                        {userData?.status !== "Pending" && (
                          <>
                            <IconClock width="18" height="18" fill="#DDB08C" />
                            <span>{t(`${basePath}status`)}</span>
                          </>
                        )}
                      </div>
                    </td>
                    <td>
                      <a className="detail" href="/personal/order/">
                        {t(`${basePath}btn`)}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer className={"bg-white"} />
    </main>
  );
};

export default Orders;
