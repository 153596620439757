import React from "react";

const IconTrash = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38 38"
      {...props}
    >
      <path
        d="M29.0899 8.02281H23.3922V6.66338C23.3922 5.74603 22.4787 5 21.3554 5H15.5901C14.4668 5 13.5533 5.74603 13.5533 6.66338V8.02281H7.8556C6.28569 8.02281 5 9.06726 5 10.3549V12.6427C5 12.9466 5.30451 13.1953 5.67668 13.1953H7.09095V30.3043C7.09095 32.6364 7.09095 32.6364 9.93977 32.6364H26.9989C29.8545 32.6364 29.8545 32.6364 29.8545 30.3043V13.1898H31.2688C31.6409 13.1898 31.9455 12.9411 31.9455 12.6372V10.3493C31.9455 9.06726 30.6665 8.02281 29.0899 8.02281ZM14.9066 6.66338C14.9066 6.35391 15.2111 6.10523 15.5901 6.10523H21.3554C21.7343 6.10523 22.0388 6.35391 22.0388 6.66338V8.02281H14.9066V6.66338ZM28 30.3043C28 31 28 31 26.9989 31H9.93977C9 31 9 31 9 30.5V14H18.5H28V17.5V21.5V30.3043ZM30.5989 11.5H7V10.3549C7 9.68066 7.02328 9.5 7.8556 9.5H29.0899C29.9154 9.5 30 9.82028 30 10.5V11.5H30.5989Z"
        fill={fill}
      />
      <path
        d="M13.9777 28.4909C14.3554 28.4909 15 28.2716 15 28V22.5V16.5C15 16.2284 14.3554 16.0546 13.9777 16.0546C13.6 16.0546 13 16.2284 13 16.5V28C13 28.2716 13.5931 28.4909 13.9777 28.4909ZM22.9678 28.4909C23.3455 28.4909 24 28.2716 24 28V16.5C24 16.2284 23.3455 16.0546 22.9678 16.0546C22.59 16.0546 22 16.2284 22 16.5V28C22 28.2716 22.59 28.4909 22.9678 28.4909Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconTrash;
