export const TRANSLATIONS_RU = {
  banner: {
    title: "Круглосуточный обмен криптовалют",
    desc: "Онлайн 24/7, выгодные курсы, надежные обмены",
    online: "Мы онлайн",
  },

  exchange: {
    amount: "Сумма",

    info: {
      rate: "Курс:",
      reserve: "Резерв:",
      min: "Минимальная сумма:",
      max: "Максимальная сумма:",
    },

    details: {
      check: {
        text: "Я согласен с",
        rules: "Пользовательским соглашением",
        and: "и",
        aml: "Политикой AML",
      },

      info: {
        title: "Детали",
        desc1:
          "Заявка обрабатывается 10 - 20 минут после фактического зачисления средств (набора 2 подтверждений(ия) и зачисления на счет биржи).",
        desc2:
          "Криптовалюты имеют высокую волатильность, поэтому отправляемая сумма будет пересчитана после получения оплаты заявки. Для расчета используются курсы бирж WhiteBIT или Binance.",
        desc3:
          "Входящая транзакция пройдет AML проверку. Если будут обнаружены высокие риски, может быть затребована верификация личности.",
        desc4:
          "Не используйте площадки CommEx, Garantex, Bitpapa и NetEx24 (полный список в Политике AML) во избежание блокировки транзакций.",
      },
    },

    btn: "Обменять сейчас",
  },

  footer: {
    copy: "Excellent Exchanger",
    text: "Все права защищены",
    rules: "Пользовательское соглашение",
    aml: "Политика AML",
  },

  aml: {
    title: "Политика AML",
    desc1:
      "Политика AML направлена на противодействие отмыванию средств, полученных преступным путем. Она включает в себя комплекс мер по выявлению рискованных транзакций, их анализу и последующей обработке.",
    data: {
      title: "Сбор данных:",
      desc1:
        "При регистрации у нас сохраняется ваш адрес электронной почты и IP-адрес.",
      desc2: "При создании заявок в них могут фиксироваться:",
      list: {
        item1: "- номера карт и счетов",
        item2: "- ФИО держателя карты",
        item3: "- номера кошельков электронных валют",
        item4: "- адреса криптовалют",
        item5:
          "- файлы, отправляемые клиентом при верификации карт и при общении во внутреннем чате",
        item6: "- текст, отправляемый клиентом во внутреннем чате",
        item7:
          "- документы, удостоверяющие личность в случае проведения верификации",
      },
    },
    collected: {
      title: "Собранные данные могут быть использованы:",
      list: {
        item1:
          "- для обеспечения функционирования нашего сайта и для предоставления вам информации и услуг",
        item2: "- для оказания вам услуг технической поддержки",
        item3:
          " - для внутреннего анализа и исследований в целях улучшения нашего сайта, а также других продуктов и услуг",
        item4:
          "- для отправки вам административных сообщений (к примеру, в случае, если вы забыли пароль)",
        item5:
          "- для обнаружения и предотвращения случаев мошенничества и злоупотребления нашим сайтом или услугами",
      },
    },
    desc2:
      "Сервис не разглашает личные данные пользователя третьим лицам. Только в исключительных обстоятельствах мы можем раскрыть ваши персональные данные по требованию законодательства, а также в целях защиты себя или третьих лиц от ущерба и противоправных действий.",
    desc3:
      "ExBox использует сервисы AMLBot и Match Systems для анализа входящих транзакций.",
    risk: {
      title:
        "Ваша транзакция может быть оценена как высокорискованная в двух случаях:",
      desc: "Если ваша транзакция соответствует одному из двух случаев, то обработка заявки будет приостановлена и мы затребуем верификацию личности и обоснование источника средств. Обратите внимание, что без верификации нельзя будет запросить отмену заявки и возврат средств.",
      item1:
        "1. Если показатели риска средств в составе транзакции превышают допустимые пределы, указанные в конце страницы.",
      item2:
        "2. Если транзакция была приостановлена криптовалютной биржей, на которую мы принимаем оплату, на основание дополнительной информацией, которой у нас может не быть.",
    },
    verification: {
      title:
        "После получения достаточных данных для верификации, начинается их проверка:",
      item1:
        "1. Если заявка была приостановлена оператором ExBox, проверка данных будет проведена администратором сервиса в рабочие часы.",
      item2:
        "2. Если заявка была приостановлена по инициативе криптовалютной биржи, мы отправляем ваши данные в техподдержку и ожидаем их решения:",
      item3:
        "2.1. Как правило проверка документов составляет до 7 рабочих дней.",
      item4:
        "2.2. Если блокировка средств на криптовалютной бирже произошла по требованию внешнего регулятора, то средства будут заморожены до момента получения разрешения на их разморозку и возврат. До этого момента, что-либо сделать будет невозможно.",
    },
    following: {
      title: "Для верификации могут быть запрошены следующие данные:",
      item1:
        "1. Фото одного из документов (паспорт, ID-карта или водительское удостоверение).",
      item2:
        "2. Селфи с этим документом и листом, на котором от руки будет написана сегодняшняя дата и подпись.",
    },
    confirm: {
      title:
        "Для подтверждения происхождения средств будет необходимо ответить на вопросы:",
      item1:
        "- Посредством какой платформы средства поступили к вам? Подтвердите это скриншотами и ссылками на транзакции.",
      item2: "- За какую услугу вы получили средства?",
      item3:
        " - На какую сумму была транзакция, а также дата и время ее проведения?",
      item4:
        "- Через какое контактное лицо вы общались с отправителем средств? Предоставьте скриншоты переписки с отправителем, где можно будет увидеть подтверждение отправки средств.",
    },
    desc4:
      "Сервис оставляет за собой право расширить список требований исходя из конкретной ситуации.",
    desc5:
      "Понимая указанные требования, Пользователь обязуется предоставить данные для верификации и ожидать решения без предъявления к Сервису каких-либо претензий.",
    permissible: {
      title: "Допустимые значения категорий риска:",
      item1:
        "- до 0% для Darknet Marketplace, Darknet Service, Drugs, Child Abuse, Stolen Credit Cards, Enforcement Action, Blackmail, Personal data EU, Personal data US, Smuggling drugs, Smuggling precursors, Smuggling people, Smuggling weapons, Smuggling, Illegal migration, Human trafficking, Fake documents, Fake document rendering, Illegal weapons, Fraudulent Exchange, Gambling, Illegal Service, PEP, Сorruption, Ransom, Malware (excluding Ransom), DDOS service, Phishing service, Stolen Coins, Scam ICO, High Risk country, Sanction country, Terrorism, State bodies",
      item2: "- до 5% для Scam",
      item3:
        "- до 10% для Precursors, China precursors manufacturing, Personal data RU, Personal data CIS, Personal data other, Stolen DataBase, Laundering of money, Illegal financial transactions, Paramilitary organization, Cracked software",
      item4: "- до 25% для Mixing Service, Online Marketplace, Bribge",
      item5:
        "- до 50% для Exchange With High ML Risk, Exchange With Very High ML Risk, DEX (excluding Bridges), P2P Exchange With High ML Risk, P2P Exchange With Very High ML Risk, OTC Exchange With High ML Risk, OTC Exchange With Very High ML Risk, ATM",
    },
  },

  terms: {
    title: "Пользовательское соглашение",
    desc1: "Термины и определения",
    desc2: "Вебсайт - сайт в сети Интернет по адресу exbox.app",
    desc3:
      "Верификация карты - это проверка принадлежности карты или счета её владельцу",
    desc4:
      "Заявка - запись в системе управления Сервиса, созданная Пользователем путем заполнения формы на Вебсайте, содержащая намерение Пользователя произвести обмен одной валюты на другую, его идентификационные данные и реквизиты для оплаты.",
    desc5:
      "Криптовалюта - Bitcoin, Litecoin, Ethereum и любые иные валюты, основанные на блокчейне.",
    desc6:
      "Пользователь - любое физическое лицо, использующее услуги сервиса ExBox.app, присоединившееся к настоящему Пользовательскому соглашению (далее - Соглашению)",
    desc7: "Сервис - обменный сервис ExBox",
    desc8: "Стороны - совместное наименование Сервиса и Пользователя",
    desc9:
      "Учетная запись - запись в системе Сервиса, представляющая собой средство учета информации о Пользователе, идентификатором которой выступает электронная почта, указанная при создании заявки и автоматической регистраци. Электронная валюта - Perfect Money, Payeer, Advanced Cash и любые иные платежные системы классического типа.",
    subject: {
      title: "1. Предмет Соглашения",
      list: {
        item1:
          "1.1. Предметом Соглашения, заключаемого между Сторонами путем присоединения Пользователя к настоящему Соглашению через создание заявки на Вебсайте, является оказание Пользователю услуг Сервиса.",
        item2:
          "1.2. Акцептом Соглашения является совершение Пользователем действия по созданию заявки на Вебсайте и его ознакомление с условиями настоящего Соглашения.",
        item3:
          "1.3. Фиксация факта акцепта Пользователем Соглашения осуществляется Сервисом в электронном виде и ранится в аппаратнопрограммном комплексе Сервиса.",
        item4:
          "1.4. Администрация ExBox вправе в любое время внести изменения или дополнения в настоящее Соглашение, которое вступает в силу немедленно после публикации на Вебсайте Сервиса.",
      },
    },
    personal: {
      title: "2. Правила пользования Учетной записью",
      list: {
        item1:
          "2.1. Сервис ExBox предназначен для использования исключительно в личных целях. Регистрируясь на сайте, Вы даете свое согласие на передачу Сервису достоверных данных о себе.",
        item2:
          "2.2. Мы также можем проводить дополнительные проверки вашей информации и запрашивать у вас любые данные, связанные с обменом (паспорт, фотографию карты, видео верификацию карты, квитанцию об отправке средств и другие необходимые данные) по любой причине, связанной с использованием вами Сервиса.",
        item3:
          "2.3. Администрация Сервиса может в любое время и по своему собственному усмотрению отказать вам в возможности использовать Учетную запись или приостановить выполнение любой Заявки до окончания рассмотрения информации, предоставленной Вами.",
        item4:
          "2.4. Ответственность за действия третьих лиц. Ваша Учетная запись предназначена только для личного использования, а не для использования или доступа какой-либо третьей стороны. В любом случае, вы несете полную ответственность за все действия или бездействие любой третьей стороны, осуществляющей доступ и/или использующей Вашу Учетную запись.",
        item5:
          "2.5. Вы соглашаетесь с тем, что не будете использовать Сервис для осуществления какой-либо преступной деятельности, включая, помимо прочего, отмывание денег, незаконные азартные игры, финансирование терроризма. Если Сервис по своему собственному усмотрению определит, что деятельность на вашей Учетной записи является подозрительной или связана с какой-либо запрещенной деятельностью или незаконной операцией, Сервис может приостановить действие вашего Учетной записи, заблокировать любые невыполненные транзакции.",
      },
    },
    mutual: {
      title: "3. Взаимные обязательства Сторон",
      list: {
        item1: "3.1. Сервис ExBox берет на себя следующие обязательства:",
        item2:
          "3.1.1. Обеспечивать конвертацию электронных валют, криптовалют и придерживаться при этом стандартов, установленных данным Соглашением.",
        item3:
          "3.1.2. Предоставлять поддержку информационного и технического характера Пользователям в ходе использования ими оказываемых Сервисом услуг.",
        item4:
          "3.1.3. Гарантировать конфиденциальность персональных сведений относительно совершения обменных операций.",
        item5:
          "3.1.4. Обеспечить перевод средств на счет Пользователя не позднее суток после поступления жалобы в ситуациях, перечисленных п. п. 3.2.5, 5.4—5.6 Соглашения.",
        item6: {
          title:
            "3.1.5. Хранить в тайне и не разглашать информацию по обменным операциям, а также личные данные Пользователя третьим лицам, за исключением следующих ситуаций:",
          list1: "- по законному решению суда",
          list2: "- по законному запросу компетентных органов",
          list3: "- по запросу администрации платежных систем",
        },
        item7: "3.2. Пользователь берет на себя следующие обязательства:",
        item8:
          "3.2.1. Передавать Сервису точные сведения для обеспечения оперативного проведения обмена.",
        item9: "3.2.2. Указывать корректные реквизиты для получения средств.",
        item10:
          "3.2.3. Иметь возможность получать уведомления от системы на электронную почту. Установить антивирусные программы для безопасного соединения с Вебсайтом.",
        item11: "3.2.4. Выполнять требования этого Соглашения.",
        item12:
          "3.2.5. Уведомлять администрацию Сервиса, если средства полностью либо частично не были зачислены на счет получателя. А также обо всех ситуациях, когда имели место обстоятельства, указанные в п. п. 5.4—5.6 этого Соглашения. Уведомление следует отправить в администрацию не позднее месяца после наступления соответствующего инцидента. Невыполнение этого требования приводит к перечислению спорных сумм на счет Сервиса.",
        item13:
          "3.2.6. Соблюдать законы своей страны нахождения и гражданства.",
        item14: "3.3. Права Сервиса ExBox:",
        item15:
          "3.3.1. На время останавливать работу сервиса для устранения неполадок и проведения модернизации.",
        item16:
          "3.3.2. Остановить проведение транзакции, если поступило обращение компетентных органов, технической поддержки криптовалютной биржи, партнеров либо жалоба Пользователя на мошеннические действия. Обмен останавливается на время, необходимое для выяснения ситуации.",
        item17:
          "3.3.3. Устанавливать и изменять комиссии за проведение обмена.",
        item18:
          "3.3.4. Ограничить потенциального Пользователя в доступе к своим услугам, не объясняя мотивов отказа.",
        item19:
          "3.3.6. Если в процессе обмена возникла ошибка, то Сервис вправе потребовать от Пользователя подтверждения совершенной операции путем предоставления подтверждающих файлов (скриншоты страниц аккаунта платежных систем, видео входа в аккаунт и прочее).",
        item20:
          "3.3.7. Заблокировать Пользователя за неадекватное поведение или отказ в предоставлении данных, необходимых для решения его вопроса.",
      },
    },

    regulations: {
      title: "4. Регламент совершения обменных операций",
      list: {
        item1:
          "4.1. Началом предоставления услуг считается момент создания Заявки Пользователем.",
        item2: "4.2. В Заявке Пользователь указывает:",
        item3:
          "4.2.1. Отправляемую сумму. Получаемая сумма рассчитывается автоматически относительно обменного курса Сервиса и курсов криптовалют.",
        item4: "4.2.2. Реквизиты для получения выплаты.",
        item5:
          "4.3. Сервис автоматически присваивает заявке уникальный порядковый номер и выдает реквизиты для оплаты.",
        item6:
          '4.4. Для совершения обмена Пользователь обязуется оплатить созданную им Заявку на указанные реквизиты. При этом Пользователь лично отвечает за "чистоту" отправляемых средств, поскольку предварительная проверка законности этой операции не входит в компетенцию Сервиса.',
        item7:
          "4.5. Сервис приступает к обработке заявки после фактического зачисления средств на счет.",
        item8:
          "4.6. AML проверка является обязательным этапом проведения заявки. Нарушение требований к Risk Score может привести к остановке выполнения заявки и необходимости верификации личности Пользователя.",
        item9:
          "4.7. Для выполнения заявки Сервис выставляет ордер на покупку по рынку на криптовалютной бирже. Вследствие этого курс криптовалюты может измениться и привести к уменьшению отправляемой суммы.",
        item10:
          "4.8. Время выполнения заявки указывается в деталях оформления заявки и в карточке оформленной заявки. Стандартное время выполнения заявки составляет 10 - 20 минут.",
        item11:
          "4.9. Обмен считается завершенным после отправки запроса криптовалютной биржи на вывод средств на реквизиты Пользователя.",
      },
    },

    additional: {
      title: "5. Дополнительну к регламенту совершения обменных операций",
      list: {
        item1:
          "5.1. Сервис не фиксирует курс обмена на момент создания заявки в направлениях с криптовалютами. Пересчет получаемой суммы производится после получения оплаты от Пользователя.",
        item2:
          "5.1.1 Сумма заявки может быть пересчитана только в сторону уменьшения.",
        item3:
          "5.2. Для получения платежей в криптовалюте Сервис использует корпоративные аккаунты ведущих криптовалютных бирж. Отправляя платеж на наши реквизиты, вы принимаете, что средства будут зачислены на счет криптовалютной биржи.",
        item4:
          "5.3. Полученные на биржу средства Сервис автоматически конвертирует в Tether USDT по актуальному курсу. При выполнении заявки Сервис закупает отправляемую криптовалюту из Tether USDT по текущему курсу.",
        item5:
          "5.4. Сроки зачисления денежных средств на счет зависит от выбранного направления обмена, соответствующая информация размещена на странице заявки Сервиса.",
        item6:
          "5.5. Если Пользователь перечислил сумму, величина которой не совпадает с указанной в заявке, то Сервис вправе остановить операцию и запросить подтверждающие данные для идентификации отправителя средств. После идентификации заявка может быть либо проведена, либо будет выполнен возврат. В обоих случаях Сервис взимает комиссию в размере 5% от суммы обмена, но не менее 30 USD.",
        item7:
          "5.5. Поддержка Пользователя производятся через онлайн-чат на главной странице сайта или через внутренний чат в карточке заявки.",
        item8:
          "5.6. Сервис не несет ответственности за платежи, выполненные по согласию Пользователя третьими лицами. В случае возникневения проблем по этой причине, Сервис не несет за это ответственности.",
        item9:
          "5.7. Сервис запрещено использовать для проведения незаконных операций.",
      },
    },

    responsibility: {
      title: "6. Ответственность Сервиса",
      list: {
        item1:
          "6.1. Сервис ExBox несет финансовую ответственность перед Пользователем в размере суммы средств, которые были переданы Сервису для исполнения Заявки.",
        item2:
          "6.2. Сервис ExBox не несет ответственности перед Пользователем за финансовые потери, вызванные незаконными действиями третьих сторон, которые невозможно предсказать или предотвратить.",
        item3:
          "6.3. Сервис ExBox не несет никакой юридической ответственности за последствия неправильного использования сервиса или за ошибки, сделанные Пользователем во время заполнения заявки для совершения обмена виртуальной валюты. Сервис не может выполнить отмену совершенного платежа.",
        item4:
          "6.4. Сервис не отвечает за задержки платежа либо допущенные в его процессе неточности, возникшие вследствие действий банков или систем платежей. Обменник не несет ответственности за последующие после обменной операции, возможные, блокировки банковских карт и аккаунтов платёжных систем пользователя.",
        item5:
          "6.5. Сервис не компенсирует затраты, причиной которых стали технические проблемы обменного сервиса, криптовалютных бирж, задержки либо ошибки в процессе совершения обмена.",
        item6:
          "6.6. Сервис ExBox не несёт ответственности за частичное или полное несоблюдение данного Соглашения, если таковое явилось следствием обстоятельств непреодолимой силы, которые невозможно предотвратить разумными мерами.",
      },
    },

    user: {
      title: "7. Ответственность Пользователя",
      list: {
        item1:
          "7.1. В случае согласия с настоящими Условиями, Пользователь заявляет и подтверждает, что:",
        item2:
          "7.1.1. Он предоставил верную и правдивую информацию о себе, а также подлинные идентификационные данные.",
        item3:
          "7.1.2. Он не является участником операций или сделок по отмыванию доходов, полученных преступным путем.",
        item4:
          "7.1.3. Использование Сервиса не связано с осуществлением преступной и/или террористической деятельности.",
        item5:
          "7.2. Пользователь обязуется соблюдать налоговое законодательство страны, резидентом которой он является.",
        item6:
          "7.3. Пользователь обязуется не пользоваться техническим сбоем на стороне обменного пункта, либо некорректной трансляцией курсов на мониторингах с целью обогащения. Попытка использования Пользователем технического сбоя, при которой Сервис отдает больше средств, чем получает, будет расцениваться как мошенничество, заявка будет приостановлена, а средства возвращены с вычетом комиссии на отправку.",
      },
    },

    refunds: {
      title: "8. Возврат платежей",
      list: {
        item1:
          "8.1. Выполнение Заявки имеет необратимый эффект, отменить ее невозможно.",
        item2:
          "8.2. Для возврата платежа Сервис закупает требуемую валюту по текущему курсу на сумму Tether USDT, которую он получил при конвертации входящего депозита.",
        item3:
          "8.3. Если клиент затребовал возврат платежа по собственной инициативе, то Сервис взимает комиссию в размере 2%, но не менее 30 USD, и комиссию на отправку платежа.",
      },
    },

    unforeseen: {
      title: "9. Непредвиденные обстоятельства",
      list: {
        item1:
          "9.1. При возникновении непреодолимых обстоятельств Стороны освобождаются от наступления ответственности за невыполнение либо несвоевременное выполнение условий данного Соглашения. Под непреодолимыми обстоятельствами понимаются война, теракт, пожар, наводнение, беспорядки, хакерское проникновение, акты местных властей или сбои в энергоснабжении и отсутствие доступа к сетям, в числе которых Интернет и услуги связи.",
        item2:
          "9.2. Если в результате отправки средств Пользователем счет Сервиса был заблокирован, то заявка, по которой была получена оплата, не проводится и оплата удерживается до выяснения причин блокировки.",
        item3:
          "9.3. Если в результате отправки средств Сервисом счет пользователя был заблокирован, то Пользователю следует обратиться в техподдержку интернет-банка, онлайн кошелька, биржи для выяснений причин блокировки. Процесс общения Пользователя с Сервисом должен осуществляться во внутреннем чате в заявке для фиксирования процесса разблокировки.",
      },
    },

    storage: {
      title: "10. Условия обработки и хранения персональных данных",
      list: {
        item1:
          "10.1. Данные Пользователя не подлежат распространению третьим лицам, они сохраняются только на стороне сервиса и служат для проведения заявок.",
        item2:
          "10.2. Если к Сервису обращаются правоохранительные органы, техподдержка онлайн-кошельков или криптовалютных бирж для разъяснения информации о переводах или о клиентах, то Сервис оставляет за собой право передавать персональные сведения.",
      },
    },
  },

  order: {
    side: {
      orders: "Мои заявки",
      logout: "Выход",
    },

    main: {
      order: "Заявка",
      paid: "Не оплачена",
      chat: "открыть чат",
      list: {
        item1: "Направление:",
        item2: "Дата/время создания:",
      },
      btn: "Я оплатил",
      showQr: "Показать QR код",
      important: {
        title: "ВАЖНО",
        list: {
          item1:
            "Заявка обрабатывается 10 - 20 минут после фактического зачисления средств (набора 500 подтверждений(ия) и зачисления на счет биржи).",
          item2:
            "Криптовалюты имеют высокую волатильность, поэтому отправляемая сумма будет пересчитана после получения оплаты заявки. Для расчета используются курсы бирж WhiteBIT или Binance.",
          item3:
            "Входящая транзакция пройдет AML проверку. Если будут обнаружены высокие риски, может быть затребована верификация личности.",
          item4:
            "Не используйте площадки CommEx, Garantex, Bitpapa и NetEx24 (полный список в Политике AML) во избежание блокировки транзакций.",
        },
      },
      btnBack: "Вернуться к заказам",
      btnDelte: "Удалить заявку",
      modalQr: {
        title: "Ваш QR код",
      },
      modalDelete: {
        title: "Вы действительно хотите удалить данную заявку?",
        btnYes: "ДА",
        btnNo: "НЕТ",
      },
      modalPay: {
        desc: "Вы действительно отправили оплату на предоставленные реквизиты в заявке?",
        btnYes: "Да, оплатил",
        btnNo: "Нет, не оплатил",
      },
    },
  },

  orders: {
    title: "Мои заявки",
    side: {
      orders: "Мои заявки",
      logout: "Выход",
    },
    btn: "Детали",
    status: "Не оплачена",
  },
};
