import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

import { useTranslation } from "react-i18next";

const Tеrms = () => {
  const { t } = useTranslation();
  const basePath = "terms.";

  return (
    <main>
      <NavBar className="bg-white" />
      <div className="order">
        <div className="container p-col-6">
          <div className="box rules">
            <h3>
              <b>{t(`${basePath}title`)}</b>
            </h3>
            <b>{t(`${basePath}desc1`)}</b>
            <br />
            {t(`${basePath}desc1`)}
            <br />
            {t(`${basePath}desc2`)}
            <br />
            {t(`${basePath}desc3`)}
            <br />
            {t(`${basePath}desc4`)}
            <br />
            {t(`${basePath}desc5`)}
            <br />
            {t(`${basePath}desc6`)}
            <br />
            {t(`${basePath}desc7`)}
            <br />
            {t(`${basePath}desc8`)}
            <br />
            <br />
            <b>{t(`${basePath}subject.title`)}</b>
            <br />
            {t(`${basePath}subject.list.item1`)}
            <br />
            {t(`${basePath}subject.list.item2`)}
            <br />
            {t(`${basePath}subject.list.item3`)}
            <br />
            {t(`${basePath}subject.list.item4`)}
            <br />
            <br />
            <b>{t(`${basePath}personal.title`)}</b>
            <br />
            {t(`${basePath}personal.list.item1`)}
            <br />
            {t(`${basePath}personal.list.item2`)}
            <br />
            {t(`${basePath}personal.list.item3`)}
            <br />
            {t(`${basePath}personal.list.item4`)}
            <br />
            {t(`${basePath}personal.list.item5`)}
            <br />
            <br />
            <b>{t(`${basePath}mutual.title`)}</b>
            <br />
            {t(`${basePath}mutual.list.item1`)}
            <br />
            {t(`${basePath}mutual.list.item2`)}
            <br />
            {t(`${basePath}mutual.list.item3`)}
            <br />
            {t(`${basePath}mutual.list.item4`)}
            <br />
            {t(`${basePath}mutual.list.item5`)}
            <br />
            {t(`${basePath}mutual.list.item6.title`)}
            <br />
            {t(`${basePath}mutual.list.item1.list1`)}
            <br />
            {t(`${basePath}mutual.list.item1.list2`)}
            <br />
            {t(`${basePath}mutual.list.item1.list3`)}
            <br />
            {t(`${basePath}mutual.list.item7`)}
            <br />
            {t(`${basePath}mutual.list.item8`)}
            <br />
            {t(`${basePath}mutual.list.item9`)}
            <br />
            {t(`${basePath}mutual.list.item10`)}
            <br />
            {t(`${basePath}mutual.list.item11`)}
            <br />
            {t(`${basePath}mutual.list.item12`)}
            <br />
            {t(`${basePath}mutual.list.item13`)}
            <br />
            {t(`${basePath}mutual.list.item14`)}
            <br />
            {t(`${basePath}mutual.list.item15`)}
            <br />
            {t(`${basePath}mutual.list.item16`)}
            <br />
            {t(`${basePath}mutual.list.item17`)}
            <br />
            {t(`${basePath}mutual.list.item18`)}
            <br />
            {t(`${basePath}mutual.list.item19`)}
            <br />
            {t(`${basePath}mutual.list.item20`)}
            <br />
            <br />
            <b>{t(`${basePath}regulations.title`)}</b>
            <br />
            {t(`${basePath}regulations.list.item1`)}
            <br />
            {t(`${basePath}regulations.list.item2`)}
            <br />
            {t(`${basePath}regulations.list.item3`)}
            <br />
            {t(`${basePath}regulations.list.item4`)}
            <br />
            {t(`${basePath}regulations.list.item5`)}
            <br />
            {t(`${basePath}regulations.list.item6`)}
            <br />
            {t(`${basePath}regulations.list.item7`)}
            <br />
            {t(`${basePath}regulations.list.item8`)}
            <br />
            {t(`${basePath}regulations.list.item9`)}
            <br />
            {t(`${basePath}regulations.list.item10`)}
            <br />
            {t(`${basePath}regulations.list.item11`)}
            <br />
            <br />
            <b>{t(`${basePath}additional.title`)}</b>
            <br />
            {t(`${basePath}additional.list.item1`)}
            <br />
            {t(`${basePath}additional.list.item2`)}
            <br />
            {t(`${basePath}additional.list.item3`)}
            <br />
            {t(`${basePath}additional.list.item4`)}
            <br />
            {t(`${basePath}additional.list.item5`)}
            <br />
            {t(`${basePath}additional.list.item6`)}
            <br />
            {t(`${basePath}additional.list.item7`)}
            <br />
            {t(`${basePath}additional.list.item8`)}
            <br />
            {t(`${basePath}additional.list.item9`)}
            <br />
            <br />
            <b>{t(`${basePath}responsibility.title`)}</b>
            <br />
            {t(`${basePath}responsibility.list.item1`)}
            <br />
            {t(`${basePath}responsibility.list.item2`)}
            <br />
            {t(`${basePath}responsibility.list.item3`)}
            <br />
            {t(`${basePath}responsibility.list.item4`)}
            <br />
            {t(`${basePath}responsibility.list.item5`)}
            <br />
            {t(`${basePath}responsibility.list.item6`)}
            <br />
            <br />
            <b>{t(`${basePath}user.title`)}</b>
            <br />
            {t(`${basePath}user.list.item1`)}
            <br />
            {t(`${basePath}user.list.item2`)}
            <br />
            {t(`${basePath}user.list.item3`)}
            <br />
            {t(`${basePath}user.list.item4`)}
            <br />
            {t(`${basePath}user.list.item5`)}
            <br />
            {t(`${basePath}user.list.item6`)}
            <br />
            <br />
            <b>{t(`${basePath}refunds.title`)}</b>
            <br />
            {t(`${basePath}refunds.list.item1`)}
            <br />
            {t(`${basePath}refunds.list.item2`)}
            <br />
            {t(`${basePath}refunds.list.item3`)}
            <br />
            <br />
            <b>{t(`${basePath}unforeseen.title`)}</b>
            <br />

            <br />
            {t(`${basePath}unforeseen.list.item2`)}
            <br />
            {t(`${basePath}unforeseen.list.item3`)}
            <br />
            <br />
            <b>{t(`${basePath}storage.title`)}</b>
            <br />
            {t(`${basePath}storage.list.item1`)}
            <br />
            {t(`${basePath}storage.list.item2`)}
          </div>
        </div>
      </div>
      <Footer className={"bg-white"} />
    </main>
  );
};

export default Tеrms;
