import React from "react";

const IconAngleDownBig = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 54"
      {...props}
    >
      <path
        d="M6 49.586L19.561 27.293L6 5H3L16.732 27.293L3 49.586H6Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconAngleDownBig;
