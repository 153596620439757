import React from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

function Banner() {
  const { t } = useTranslation();
  const basePath = "banner.";

  return (
    <div className="banner">
      <div className="container">
        <Row>
          <Col lg={24} md={24} sm={24} xs={24} className="p-0">
            <div className="d-flex justify-end">
              <div className="btn-online">{t(`${basePath}online`)}</div>
            </div>
          </Col>
        </Row>
        <Row align="middle">
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="text-center">
              <h1>{t(`${basePath}title`)}</h1>
            </div>
            <p>{t(`${basePath}desc`)}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Banner;
