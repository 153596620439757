import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const Policy = () => {
  const { t } = useTranslation();
  const basePath = "aml.";

  return (
    <main>
      <NavBar className="bg-white" />
      <div className="order">
        <div className="container p-col-6">
          <div className="box rules">
            <h3>{t(`${basePath}title`)}</h3>
            {t(`${basePath}desc1`)}
            <br />
            <br />
            {t(`${basePath}data.title`)}
            <br />
            {t(`${basePath}data.desc1`)}
            <br />
            {t(`${basePath}data.desc2`)}
            <br />
            {t(`${basePath}data.list.item1`)}
            <br />
            {t(`${basePath}data.list.item2`)}
            <br />
            {t(`${basePath}data.list.item3`)}
            <br />
            {t(`${basePath}data.list.item4`)}
            <br />
            {t(`${basePath}data.list.item5`)}
            <br />
            {t(`${basePath}data.list.item6`)}
            <br />
            {t(`${basePath}data.list.item7`)}
            <br />
            <br />
            {t(`${basePath}collected.title`)}
            <br />
            {t(`${basePath}collected.list.item1`)}
            <br />
            {t(`${basePath}collected.list.item2`)}
            <br />
            {t(`${basePath}collected.list.item3`)}
            <br />
            {t(`${basePath}collected.list.item4`)}
            <br />
            {t(`${basePath}collected.list.item5`)}
            <br />
            {t(`${basePath}desc2`)}
            <br />
            <br />
            {t(`${basePath}desc3`)}
            <br />
            {t(`${basePath}risk.title`)}
            <br />
            {t(`${basePath}risk.item1`)}
            <br />
            {t(`${basePath}risk.item2`)}
            <br />
            {t(`${basePath}risk.desc`)}
            <br />
            <br />
            {t(`${basePath}verification.title`)}
            <br />
            {t(`${basePath}verification.item1`)}
            <br />
            {t(`${basePath}verification.item3`)}
            <br />
            {t(`${basePath}verification.item3`)}
            <br />
            {t(`${basePath}verification.item4`)}
            <br />
            <br />
            {t(`${basePath}following.title`)}
            <br />
            {t(`${basePath}following.item1`)}
            <br />
            {t(`${basePath}following.item2`)}
            <br />
            {t(`${basePath}confirm.title`)}
            <br />
            {t(`${basePath}confirm.item1`)}
            <br />
            {t(`${basePath}confirm.item2`)}
            <br />
            {t(`${basePath}confirm.item3`)}
            <br />
            {t(`${basePath}confirm.item4`)}
            <br />
            {t(`${basePath}desc4`)}
            <br />
            <br />
            {t(`${basePath}desc5`)}
            <br />
            <br />
            {t(`${basePath}permissible.title`)}
            <br />
            {t(`${basePath}permissible.item1`)}
            <br />
            {t(`${basePath}permissible.item2`)}
            <br />
            {t(`${basePath}permissible.item3`)}
            <br />
            {t(`${basePath}permissible.item4`)}
            <br />
            {t(`${basePath}permissible.item5`)}
          </div>
        </div>
      </div>
      <Footer className={"bg-white"} />
    </main>
  );
};

export default Policy;
