import React from "react";
import { Header } from "antd/es/layout/layout";
import { Dropdown } from "antd";
import { languages } from "../i18n";
import { useLanguage } from "../contexts/LanguageContext";
import { IconAngleDown, IconUser } from "../utils/Icons";

const langIcons = {
  "ru-RU": require("../assets/img/flags/ru.jpg"),
  "en-US": require("../assets/img/flags/en.png"),
};

function NavBar({ className, userData }) {
  const { changeLanguage } = useLanguage();
  const activeLang = localStorage.getItem("i18nextLng");

  const languagesArr = languages.map((item, index) => ({
    key: index,
    label: (
      <span className="item-lang">
        <img src={langIcons[item]} alt="" />
      </span>
    ),
  }));

  const onLangChange = ({ key }) => {
    const lang = languages[+key];
    localStorage.setItem("i18nextLng", lang);
    changeLanguage(lang);
  };

  return (
    <Header className={className}>
      <div className="container">
        <div className="head">
          <a href="/" className="logo">
            <img src={require("../assets/img/logo.svg").default} alt="" />
          </a>

          <div className="auth">
            <a className="person" href="/personal/orders/">
              <IconUser width="36" height="36" fill="#fff" />
              {userData?.email}
            </a>
            <Dropdown
              className="lang"
              menu={{ items: languagesArr, onClick: onLangChange }}
              trigger={["click"]}
            >
              <button type="button" className={`dropdown-btn`}>
                <img src={langIcons[activeLang]} alt="" />
                <span className="arrow">
                  <IconAngleDown width="24" height="24" fill="#A1B2B6" />
                </span>
              </button>
            </Dropdown>
          </div>
        </div>
      </div>
    </Header>
  );
}

export default NavBar;
