import React from "react";

const IconChats = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 58 58"
      {...props}
    >
      <path
        d="M50 26.5566C50 17.519 42 10.0718 32.1818 9.10796C31.8182 9.10796 25 7.97169 21 13.0718C23.9869 11.1364 30 11.5718 30 11.5718C40.0415 11.5718 46.9927 19.7824 46.9927 28.8199C46.9927 32.2768 46 38.0718 41.5 41.5718C45.5 43.5718 50 44.8084 50 44.8084C47.96 42.7605 48.0676 39.1074 47.8182 36.1207C49.7171 33.5362 50 29.8871 50 26.5566Z"
        fill={fill}
      />
      <path
        d="M26.1818 13.4561C16.1404 13.4561 8 20.7824 8 29.82C8 33.1505 9.10836 36.2468 11.0073 38.8312C10.7578 41.8172 10.04 46.0232 8 48.0718C8 48.0718 14.2335 47.1935 18.456 44.6339C20.8015 45.626 23.4189 46.1838 26.1818 46.1838C36.2233 46.1838 44.3636 38.8575 44.3636 29.82C44.3636 20.7824 36.2233 13.4561 26.1818 13.4561Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconChats;
