export const TRANSLATIONS_EN = {
  banner: {
    title: "Always online cryptoexchanger",
    desc: "Online 24/7, good rates, trustworthy team",
    online: "WE ARE ONLINE",
  },

  exchange: {
    amount: "Amount",

    info: {
      rate: "Rate:",
      reserve: "Reserve:",
      min: "Min. amount:",
      max: "Max. amount:",
    },

    details: {
      check: {
        text: "I agree to",
        rules: "User agreement",
        and: "and",
        aml: "AML Policy",
      },

      info: {
        title: "Details",
        desc1:
          "The order is processed 10 - 20 minutes after the funds are actually credited (get 2 confirmation(s) and credited to the exchange account).",
        desc2:
          "Cryptocurrencies have high volatility, so the amount sent will be recalculated after receiving payment for the order. For calculations, the exchange rates of WhiteBIT or Binance are used.",
        desc3:
          "The incoming transaction will pass AML verification. If high risks are found, identity verification may be required.",
        desc4:
          "Do not use CommEx, Garantex, Bitpapa and NetEx24 platforms (full list in the AML Policy) to avoid blocking transactions.",
      },
    },

    btn: "Exchange now",
  },

  footer: {
    copy: "innovation money exchanger",
    text: "All rights reserved",
    rules: "User agreement",
    aml: "AML policy",
  },

  aml: {
    title: "AML Policy",
    desc1:
      "The AML policy is aimed at countering the laundering of proceeds from crime. It includes a set of measures to identify risky transactions, their analysis and subsequent processing.",
    data: {
      title: "Data collection:",
      desc1: " When you register, we store your email address and IP address.",
      desc2: "When creating an order, they may contain:",
      list: {
        item1: "- card and account numbers",
        item2: "- Full name of the cardholder",
        item3: "- numbers of e-currency wallets",
        item4: "- addresses of cryptocurrencies",
        item5:
          "- files sent by the client when verifying cards and when communicating in the internal chat",
        item6: "- text sent by the client in the internal chat",
        item7: "- identity documents in case of verification",
      },
    },
    collected: {
      title: "The collected data can be used:",
      list: {
        item1:
          "- to operate our website and to provide you with information and services",
        item2: "- to provide you with technical support",
        item3:
          " - for internal analysis and research to improve our website and other products and services",
        item4:
          "- to send you administrative messages (for example, if you forgot your password)",
        item5:
          "- to detect and prevent fraud and abuse of our website or services",
      },
    },
    desc2:
      "The service does not disclose the user's personal data to third parties. Only in exceptional circumstances can we disclose your personal data as required by law, as well as in order to protect ourselves or third parties from damage and illegal actions.",
    desc3:
      "ExBox uses AMLBot and Match Systems services to analyze incoming transactions.",
    risk: {
      title: "Your transaction can be marked as high-risk in two cases:",
      desc: "If your transaction matches one of the two cases, then the processing of the order will be suspended and we will require verification of your identity and justification of the source of funds. Please note that without verification, it will not be possible to request a cancellation of the order and a refund.",
      item1:
        "1. If the risk values of the funds in the transaction exceed the allowable limits indicated at the end of the page.",
      item2:
        "2. If the transaction was suspended by the cryptocurrency exchange we accept payment to, based on additional information that we may not have.",
    },
    verification: {
      title:
        " After receiving sufficient data for verification, their verification begins:",
      item1:
        "1. If the order was suspended by the ExBox operator, data verification will be carried out by the service administrator during business hours.",
      item2:
        "2. If the order was suspended at the initiative of the cryptocurrency exchange, we send your data to technical support and await their decision:",
      item3: "2.1. Usually, document verification takes up to 7 business days.",
      item4:
        "2.2. If the blocking of funds on the cryptocurrency exchange occurred at the request of an external regulator, then the funds will be frozen until permission is received to unfreeze and return them. Until then, nothing can be done.",
    },
    following: {
      title: "The following data may be requested for verification:",
      item1:
        "1. Photo of one of the documents (passport, ID-card or driver's license).",
      item2:
        "2. Selfie with this document and a sheet on which today's date and signature will be written by hand.",
    },
    confirm: {
      title:
        "To confirm the origin of funds, it will be necessary to answer the following questions:",
      item1:
        "- Through which platform did the funds come to you? Confirm this with screenshots and transaction links.",
      item2: "- For what service did you receive funds?",
      item3:
        " - What was the amount of the transaction, as well as the date and time of its execution?",
      item4:
        "- Through which contact person did you communicate with the sender of funds? Provide screenshots of the correspondence with the sender, where we can see the confirmation of sending funds.",
    },
    desc4:
      "The service reserves the right to expand the list of requirements based on the specific situation.",
    desc5:
      " Understanding these requirements, the User undertakes to provide data for verification and wait for a decision without presenting any claims to the Service.",
    permissible: {
      title: "Permissible risk category values:",
      item1:
        "- up to 0% для Darknet Marketplace, Darknet Service, Drugs, Child Abuse, Stolen Credit Cards, Enforcement Action, Blackmail, Personal data EU, Personal data US, Smuggling drugs, Smuggling precursors, Smuggling people, Smuggling weapons, Smuggling, Illegal migration, Human trafficking, Fake documents, Fake document rendering, Illegal weapons, Fraudulent Exchange, Gambling, Illegal Service, PEP, Сorruption, Ransom, Malware (excluding Ransom), DDOS service, Phishing service, Stolen Coins, Scam ICO, High Risk country, Sanction country, Terrorism, State bodies",
      item2: "- up to 5% для Scam",
      item3:
        "- up to 10% для Precursors, China precursors manufacturing, Personal data RU, Personal data CIS, Personal data other, Stolen DataBase, Laundering of money, Illegal financial transactions, Paramilitary organization, Cracked software",
      item4: "- up to 25% для Mixing Service, Online Marketplace, Bribge",
      item5:
        "- up to 50% для Exchange With High ML Risk, Exchange With Very High ML Risk, DEX (excluding Bridges), P2P Exchange With High ML Risk, P2P Exchange With Very High ML Risk, OTC Exchange With High ML Risk, OTC Exchange With Very High ML Risk, ATM",
    },
  },

  terms: {
    title: "User Agreement",
    desc1: "Terms and definitions",
    desc2: "Website - a site on the Internet at exbox.app",
    desc3:
      "Card verification - verification of the ownership of a card or account by its owner",
    desc4:
      "Order - a record in the management system of the Service, created by the User by filling out a form on the Website, containing the User's intention to exchange one currency for another, his identification data and payment details.",
    desc5:
      "Cryptocurrency - Bitcoin, Litecoin, Ethereum and any other blockchain-based currencies.",
    desc6:
      "User - any individual using the services of the ExBox.app service, who has acceded to this User Agreement (hereinafter referred to as the Agreement)",
    desc7: "Service - exchange service ExBox",
    desc8: "Parties - the joint name of the Service and the User",
    desc9:
      "Personal account - a record in the Service system, which is a means of recording information about the User, the identifier of which is the email specified when creating the order and automatic registration. Electronic currency - Perfect Money, Payeer, Advanced Cash and any other classical payment systems.",
    subject: {
      title: "1. Subject of the Agreement",
      list: {
        item1:
          "1.1. The subject of the Agreement concluded between the Parties by joining the User to this Offer through the creation of an order on the Website is the provision of the Service to the User.",
        item2:
          "1.2. Acceptance of the Agreement is the User's action to create an order on the Website and familiarization with the terms of this Agreement.",
        item3:
          "1.3. The fact of acceptance by the User of the Agreement is recorded by the Service in electronic form and is stored in the hardware and software complex of the Service.",
        item4:
          "1.4. The ExBox Administration has the right to make changes or additions to this Agreement at any time, which shall enter into force immediately after publication on the Website of the Service.",
      },
    },
    personal: {
      title: "2. Personal account Terms",
      list: {
        item1:
          "2.1. The ExBox service is intended for personal use only. By registering on the Website, you consent to the transfer of reliable data about yourself to the Service.",
        item2:
          "2.2. We may also perform additional checks on your information and request from you any information related to the exchange (passport, card photo, video card verification, receipt of funds sent and other necessary data) for any reason related to your use of the Service.",
        item3:
          "2.3. The Service Administration may at any time and at its sole discretion deny you the opportunity to use your Personal Account or suspend the execution of any Order until the end of the consideration of the information provided by you.",
        item4:
          "2.4. Responsibility for the actions of third parties. Your Personal account is for personal use only and not for use or access by any third party. In any case, you are solely responsible for all acts or omissions of any third party accessing and/or using your Account.",
        item5:
          "2.5. You agree that you will not use the Service to engage in any criminal activity, including but not limited to money laundering, illegal gambling, terrorist financing. If the Service, in its sole discretion, determines that the activity on your Account is suspicious or related to any prohibited activity or illegal operation, the Service may suspend your Personal Account, block any outstanding transactions.",
      },
    },
    mutual: {
      title: "3. Mutual obligations of the Parties",
      list: {
        item1: "3.1. The ExBox service undertakes the following obligations:",
        item2:
          "3.1.1. Ensure the conversion of electronic currencies, cryptocurrencies and adhere to the standards established by this Agreement.",
        item3:
          "3.1.2. Provide informational and technical support to Users in the course of their use of the services provided by the Service.",
        item4:
          "3.1.3. Guarantee the confidentiality of personal information regarding the performance of exchange transactions.",
        item5:
          "3.1.4. Ensure the transfer of funds to the User's account no later than one day after the receipt of the complaint in the situations listed in clauses 3.2.5, 5.4-5.6 of the Agreement.",
        item6: {
          title:
            "3.1.5. Keep secret and not disclose information on exchange transactions, as well as the User's personal data to third parties, except for the following situations:",
          list1: "- by legal decision of the court",
          list2: "- at the legitimate request of the competent authorities",
          list3: "- at the request of the administration of payment systems",
        },
        item7: "3.2. The user undertakes the following obligations:",
        item8:
          "3.2.1. Provide the Service with accurate information to ensure the prompt exchange.",
        item9: "3.2.2. Specify the correct details for receiving funds.",
        item10:
          "3.2.3. Be able to receive notifications from the system by e-mail. Install anti-virus software to securely connect to the Website.",
        item11: "3.2.4. Comply with the requirements of this Agreement.",
        item12:
          "3.2.5. Notify the administration of the Service if the funds have not been fully or partially credited to the recipient's account. And also about all situations when the circumstances specified in clauses 5.4-5.6 of this Agreement occurred. The notification should be sent to the administration no later than one month after the occurrence of the relevant incident. Failure to comply with this requirement leads to the transfer of disputed amounts to the account of the Service.",
        item13:
          "3.2.6. Comply with the laws of your country of residence and citizenship.",
        item14: "3.3. ExBox Service Rights:",
        item15:
          "3.3.1. Temporarily stop the service for troubleshooting and upgrading.",
        item16:
          "3.3.2. Stop the transaction if a request is received from the competent authorities, technical support of the cryptocurrency exchange, partners, or the User complains about fraudulent activities. The exchange stops for the time necessary to clarify the situation.",
        item17: "3.3.3. Set and change commissions for the exchange.",
        item18:
          "3.3.4. Restrict a potential User in access to their services without explaining the reasons for refusal.",
        item19:
          "3.3.6. If an error occurs during the exchange process, the Service has the right to require the User to confirm the transaction by providing confirmation files (screenshots of the payment system account pages, account login video, etc.).",
        item20:
          "3.3.7. Block the User for inappropriate behavior or refusal to provide the data necessary to resolve his issue.",
      },
    },

    regulations: {
      title: "4. Regulations for performing exchange transactions",
      list: {
        item1:
          "4.1. The beginning of the provision of services is considered the moment the order is created by the User.",
        item2: "4.2. In the order the User specifies:",
        item3:
          "4.2.1. Sending amount. The amount received is calculated automatically in relation to the exchange rate of the Service and the rates of cryptocurrencies.",
        item4: "4.2.2. Requisites for receiving payment.",
        item5:
          "4.3. The service automatically assigns a unique serial number to the order and issues payment details.",
        item6:
          '4.4. To make an exchange, the User undertakes to pay the order he created for the specified details. At the same time, the User is personally responsible for the "purity" of the funds sent, since preliminary verification of the legality of this operation is not within the competence of the Service.',
        item7:
          "4.5. The service starts processing the order after the actual transfer of funds to the account.",
        item8:
          "4.6. AML verification is a mandatory step in the execution of an order. Violation of the requirements for the Risk Score may lead to the suspension of the execution of the order and the need to verify the identity of the User.",
        item9:
          "4.7. To execute an order, the Service places an order to buy by market on a cryptocurrency exchange. As a result, the cryptocurrency exchange rate may change and lead to a decrease in the amount sent.",
        item10:
          "4.8. The order execution time is indicated in the details of the order execution and in the issued order card. The standard order execution time is 10 - 20 minutes.",
        item11:
          "4.9. The exchange is considered completed after sending a request from the cryptocurrency exchange to withdraw funds to the User's details.",
      },
    },

    additional: {
      title: "5. Additional to the regulation of exchange transactions",
      list: {
        item1:
          "5.1. The service does not fix the exchange rate at the time of order creation in directions with cryptocurrencies. Recalculation of the amount received is made after receiving payment from the User.",
        item2: "5.1.1 The order amount can only be recalculated downward.",
        item3:
          "5.2. To receive payments in cryptocurrency, the Service uses corporate accounts of leading cryptocurrency exchanges. By sending a payment to our details, you accept that the funds will be credited to the account of the cryptocurrency exchange.",
        item4:
          "5.3. The funds received on the exchange are automatically converted by the Service into Tether USDT at the current exchange rate. When an order is executed, the Service buys the sent cryptocurrency from Tether USDT at the current rate.",
        item5:
          "5.4. The terms for crediting funds to the account depend on the chosen direction of exchange, the relevant information is available on the order page of the Service.",
        item6:
          "5.5. If the User has transferred an amount that does not match the amount specified in the order, the Service has the right to stop the operation and request confirmation data to identify the sender of funds. Once identified, the order can either be posted or a refund will be made. In both cases, the Service charges a commission of 5% of the exchange amount, but not less than 30 USD.",
        item7:
          "5.5. User support is provided through an online chat on the main page of the site or through an internal chat in the order card.",
        item8:
          "5.6. The Service is not responsible for payments made with the consent of the User by third parties. In case of problems for this reason, the Service is not responsible for this.",
        item9:
          "5.7. The service is prohibited to be used for illegal operations.",
      },
    },

    responsibility: {
      title: "6. Service responsibility",
      list: {
        item1:
          "6.1. The ExBox Service is financially liable to the User in the amount of the funds that were transferred to the Service for the execution of the order.",
        item2:
          "6.2. The ExBox service is not liable to the User for financial losses caused by illegal actions of third parties that cannot be predicted or prevented.",
        item3:
          "6.3. The ExBox service does not bear any legal responsibility for the consequences of improper use of the service or for errors made by the User while filling out an order for the exchange of virtual currency. The service cannot cancel the completed payment.",
        item4:
          "6.4. The service is not responsible for payment delays or inaccuracies in its process that arose as a result of the actions of banks or payment systems. The exchanger is not responsible for the subsequent possible blocking of bank cards and accounts of the user's payment systems after the exchange operation.",
        item5:
          "6.5. The service does not compensate for the costs caused by technical problems of the exchange service, cryptocurrency exchanges, delays or errors in the process of making an exchange.",
        item6:
          "6.6. The ExBox service is not responsible for partial or complete non-compliance with this Agreement, if such was the result of force majeure circumstances that cannot be prevented by reasonable measures.",
      },
    },

    user: {
      title: "7. Responsibility of the User",
      list: {
        item1:
          "7.1. In case of acceptance of these Terms, the User declares and confirms that:",
        item2:
          "7.1.1. He provided correct and truthful information about himself, as well as genuine identification data.",
        item3:
          "7.1.2. He is not a participant in operations or transactions for laundering proceeds from crime.",
        item4:
          "7.1.3. The use of the Service is not related to the implementation of criminal and/or terrorist activities.",
        item5:
          "7.2. The user undertakes to comply with the tax laws of the country of which he is a resident.",
        item6:
          "7.3. The user undertakes not to use a technical failure on the side of the exchange office, or incorrect translation of rates on monitoring in order to enrich. An attempt by the User to use a technical failure, in which the Service gives more funds than it receives, will be regarded as fraud, the order will be suspended, and the funds will be returned minus the commission for sending.",
      },
    },

    refunds: {
      title: "8. Refunds",
      list: {
        item1:
          "8.1. The execution of an order has an irreversible effect, it is impossible to cancel it.",
        item2:
          "8.2. To return the payment, the Service purchases the required currency at the current rate for the amount of Tether USDT that it received when converting the incoming deposit.",
        item3:
          "8.3. If the client requested a refund on his own initiative, the Service charges a fee of 2%, but not less than 30 USD, and a fee for sending the payment.",
      },
    },

    unforeseen: {
      title: "9. Unforeseen circumstances",
      list: {
        item1:
          "9.1. In the event of insurmountable circumstances, the Parties shall be released from liability for non-fulfillment or untimely fulfillment of the terms of this Agreement. Forceful circumstances include war, terrorist attack, fire, flood, riots, hackers, acts of local authorities or power failures and lack of access to networks, including the Internet and communication services.",
        item2:
          "9.2. If, as a result of sending funds by the User, the account of the Service was blocked, then the order for which the payment was received is not executed and the payment is withheld until the reasons for blocking are clarified.",
        item3:
          "9.3. If, as a result of sending funds by the Service, the user's account was blocked, the User should contact the technical support of the Internet bank, online wallet, exchange to find out the reasons for the blocking. The process of communication between the User and the Service must be carried out in an internal chat in order to fix the unlocking process.",
      },
    },

    storage: {
      title: "10. Terms of processing and storage of personal data",
      list: {
        item1:
          "10.1. The User's data is not subject to distribution to third parties, they are stored only on the side of the service and serve to process requests.",
        item2:
          "10.2. If the Service is contacted by law enforcement agencies, technical support of online wallets or cryptocurrency exchanges to clarify information about transfers or about clients, the Service reserves the right to transfer personal information.",
      },
    },
  },

  order: {
    side: {
      orders: "My Orders",
      logout: "Logout",
    },

    main: {
      order: "Order",
      paid: "Not paid",
      chat: "open chat",
      list: {
        item1: "Direction:",
        item2: "Date/time created:",
      },
      btn: "I have paid",
      showQr: "Show QR-code",
      important: {
        title: "Important",
        list: {
          item1:
            "The order is processed 10 - 20 minutes after the funds are actually credited (get 500 confirmation(s) and credited to the exchange account).",
          item2:
            "Cryptocurrencies have high volatility, so the amount sent will be recalculated after receiving payment for the order. For calculations, the exchange rates of WhiteBIT or Binance are used.",
          item3:
            "The incoming transaction will pass AML verification. If high risks are found, identity verification may be required.",
          item4:
            "Do not use CommEx, Garantex, Bitpapa and NetEx24 platforms (full list in the AML Policy) to avoid blocking transactions.",
        },
      },
      btnBack: "Back to orders",
      btnDelte: "Delete order",
      modalQr: {
        title: "Your QR-code",
      },
      modalDelete: {
        title: "Are you sure you want to delete this order?",
        btnYes: "YES",
        btnNo: "NO",
      },
      modalPay: {
        desc: "Are you sure you sent the payment to the details provided in the application????",
        btnYes: "Yes, paid",
        btnNo: "No, I did not pay",
      },
    },
  },

  orders: {
    title: "My Orders",
    side: {
      orders: "My Orders",
      logout: "Logout",
    },
    btn: "Details",
    tatus: "Not paid",
  },
};
