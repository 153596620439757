import React from "react";
import { useTranslation } from "react-i18next";

const Footer = ({ className }) => {
  const { t } = useTranslation();
  const basePath = "footer.";

  return (
    <div className={`footer ${className}`}>
      <div className="container">
        <div className="wrapper">
          <a href="/" className="ssl">
            <img src={require("../assets/img/icon/block.svg").default} alt="" />
            <div className="ssl-main">
              <b>SSL Encryption</b>
              <span>100% protection</span>
            </div>
          </a>
          <div className="copy">
            <b>© 2024&nbsp;ExBox - {t(`${basePath}copy`)}</b>
            {t(`${basePath}text`)}
          </div>
        </div>
        <div className="agreement">
          <a href="/tеrms_of_service/">{t(`${basePath}rules`)}</a>
          <a href="/privacy_policy/">{t(`${basePath}text`)}</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
