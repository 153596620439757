import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import emailjs from "emailjs-com";
import { Button, Input, Modal, QRCode } from "antd";
import { IconArrowRight, IconChats, IconTrash } from "../utils/Icons";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const Order = () => {
  const { t } = useTranslation();
  const basePath = "order.";

  const [userData, setUserData] = useState(null);
  const [isModalPay, setIsModalPay] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [status, setStatus] = useState(null);

  const getUserDataFromLocalStorage = () => {
    const storedUserData = localStorage.getItem("exchangeFormData");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
    }
  };

  useEffect(() => {
    getUserDataFromLocalStorage();
  }, []);

  const sendEmail = async () => {
    const serviceId = "service_e2kb56b";
    const templateId = "template_43vewe8";
    const userId = "zWUGrm_-1m3kIm23w";

    try {
      const templateParams = {
        amount: `${userData?.amount} ${userData?.infoCoinFrom.name} - ${userData.result} ${userData.infoCoinTo.name}`,
        submitTime: dayjs(userData?.submitTime).format("DD.MM.YYYY / HH:ss"),
        wallet: userData?.wallet,
        email: userData?.email,
      };

      const response = await emailjs.send(
        serviceId,
        templateId,
        templateParams,
        userId
      );
      console.log("Email sent successfully!", response);
    } catch (error) {
      console.error("Error sending email:", error);
      throw error;
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModalPay = () => {
    setIsModalPay(true);
  };

  const showModalDelete = () => {
    setIsModalDelete(true);
  };

  const handleCancel = () => {
    setIsModalPay(false);
    setIsModalOpen(false);
    setIsModalDelete(false);
  };

  const handleDeleteOrder = () => {
    localStorage.removeItem("exchangeFormData");
    setIsModalDelete(false);
    window.location.href = "/";
  };

  const handlePayOrder = async () => {
    setStatus("Pending");
    const updatedUserData = { ...userData, status: "Pending" };
    localStorage.setItem("exchangeFormData", JSON.stringify(updatedUserData));
    setIsModalPay(false);

    try {
      await sendEmail();
      window.location.reload();
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <main>
      <NavBar className="bg-white" userData={userData} />
      <div className="order">
        <div className="container p-col-6">
          <div className="box">
            <div className="side">
              <div className="list">
                <a href="/personal/orders/" className="item active">
                  <img
                    src={require("../assets/img/icon/calendar.png")}
                    alt=""
                  />
                  <span>{t(`${basePath}side.orders`)}</span>
                </a>
                <a href="/" className="item">
                  <img
                    src={require("../assets/img/icon/log-out.png")}
                    alt=""
                    style={{ maxWidth: "18px", maxHeight: "18px" }}
                  />
                  <span>{t(`${basePath}side.logout`)}</span>
                </a>
              </div>
            </div>
            <div className="main">
              <div className="title">
                <h1>
                  <span
                    className={
                      userData?.status === "Pending"
                        ? "wait-confirms"
                        : "not-paid"
                    }
                    data-status={
                      userData?.status === "Pending"
                        ? "Ожидание"
                        : t(`${basePath}main.paid`)
                    }
                  >
                    {t(`${basePath}main.order`)} #E526109
                  </span>
                </h1>
                <a href="/" className="btn-second ml-auto">
                  <IconChats width="18" height="18" fill="#61C788" />
                  {t(`${basePath}main.chat`)}
                </a>
              </div>

              <div className="order-item">
                <div className="title">{t(`${basePath}main.list.item1`)}</div>
                <div className="text">
                  {userData?.amount} {userData?.infoCoinFrom.name}
                  <i>
                    <IconArrowRight width="16" height="16" fill="#767B7F" />
                  </i>
                  {userData?.result} {userData?.infoCoinTo.name}
                </div>
              </div>
              <div className="order-item">
                <div className="title">{t(`${basePath}main.list.item2`)}</div>
                <div className="text">
                  {dayjs(userData?.submitTime).format("DD.MM.YYYY / HH:ss")}
                </div>
              </div>
              <div className="order-item">
                <div className="title">{userData?.infoCoinTo.labelWallet}:</div>
                <div className="text">{userData?.wallet}</div>
              </div>
              {userData?.memo && (
                <div className="order-item">
                  <div className="title">Message:</div>
                  <div className="text">{userData?.memo}</div>
                </div>
              )}
              {userData?.status !== "Pending" && (
                <div className="order-item">
                  <div className="title"></div>
                  <div className="text">
                    <Button
                      className="btn-primary mt-10"
                      type="primary"
                      onClick={showModalPay}
                      style={{
                        minHeight: 38,
                      }}
                    >
                      {t(`${basePath}main.btn`)}
                    </Button>
                  </div>
                </div>
              )}

              <div className="order-card">
                <Button type="primary" className="qr" onClick={showModal}>
                  {t(`${basePath}main.showQr`)}
                </Button>

                <label>{userData?.infoCoinFrom.label}</label>
                <div className="input-group">
                  <Input
                    type="text"
                    value={userData?.infoCoinFrom.wallet || "-"}
                    readOnly
                  />
                </div>
              </div>

              <div className="warning-message">
                <div className="title">
                  {t(`${basePath}main.important.title`)}
                </div>
                <div className="text">
                  <p>{t(`${basePath}main.important.list.item1`)}</p>
                  <p>{t(`${basePath}main.important.list.item2`)}</p>
                  <p>{t(`${basePath}main.important.list.item3`)}</p>
                  <p>{t(`${basePath}main.important.list.item4`)}</p>
                </div>
              </div>

              <div className="order-btns">
                <Link href="#" className="btn-second grey">
                  <IconArrowRight width="22" height="22" fill="#a9b3bf" />
                  {t(`${basePath}main.btnBack`)}
                </Link>
                <Button
                  type="primary"
                  className="btn-second red"
                  onClick={showModalDelete}
                >
                  <IconTrash width="22" height="22" fill="#df7d7d" />
                  {t(`${basePath}main.btnDelte`)}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer className={"bg-white"} />

      <Modal
        title={""}
        open={isModalPay}
        onCancel={handleCancel}
        className="modal-pay"
        footer={null}
      >
        <p>{t(`${basePath}main.modalPay.desc`)}</p>
        <div className="d-flex">
          <Button
            type="primary"
            className="btn-second"
            onClick={handlePayOrder}
          >
            {t(`${basePath}main.modalPay.btnYes`)}
          </Button>
          <Button
            type="primary"
            className="btn-second red"
            onClick={handleCancel}
          >
            {t(`${basePath}main.modalPay.btnNo`)}
          </Button>
        </div>
      </Modal>

      <Modal
        title={t(`${basePath}main.modalQr.title`)}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <QRCode value={userData?.infoCoinFrom.wallet || "-"} />
      </Modal>

      <Modal
        title={t(`${basePath}main.modalDelete.title`)}
        open={isModalDelete}
        onCancel={handleCancel}
        className="modal-delete"
        footer={null}
      >
        <div className="d-flex">
          <Button
            type="primary"
            className="btn-second"
            onClick={handleDeleteOrder}
          >
            {t(`${basePath}main.modalDelete.btnYes`)}
          </Button>
          <Button
            type="primary"
            className="btn-second red"
            onClick={handleCancel}
          >
            {t(`${basePath}main.modalDelete.btnNo`)}
          </Button>
        </div>
      </Modal>
    </main>
  );
};

export default Order;
