import React from "react";
import { ConfigProvider } from "antd";
import ruRu from "antd/es/locale/ru_RU";
import Routes from "./router/Routes";
import LanguageProvider from "./contexts/LanguageContext";

function App() {
  return (
    <ConfigProvider locale={ruRu}>
      <LanguageProvider>
        <Routes />
      </LanguageProvider>
    </ConfigProvider>
  );
}

export default App;
