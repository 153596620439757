import { Col, Row } from "antd";
import React from "react";

const Partners = () => {
  return (
    <div className="container partners">
      <Row>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <div className="item">
            <a href="https://www.bestchange.com/exbox-exchanger.html">
              <img src={require("../../assets/img/partners/best.png")} alt="" />
            </a>
          </div>
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <div className="item">
            <img src={require("../../assets/img/partners/sber.png")} alt="" />
          </div>
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <div className="item">
            <img
              src={require("../../assets/img/partners/tinkoff.png")}
              alt=""
            />
          </div>
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <div className="item">
            <img src={require("../../assets/img/partners/qiwi.png")} alt="" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Partners;
